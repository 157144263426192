import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const FeaturesList = Loadable(lazy(() => import('./AdList')));
const AddFeatures = Loadable(lazy(() => import('./AddAd')));
const Edit = Loadable(lazy(() => import('./EditAdForm')));

const AdRoutes = [
	{
		path: '/ads/list',
		element: <FeaturesList />,
		auth: authRoles.admin,
	},
	{
		path: '/ads/list/edit',
		element: <Edit />,
		auth: authRoles.admin,
	},
	{
		path: '/ads/add',
		element: <AddFeatures />,
		auth: authRoles.admin,
	},
];

export default AdRoutes;
