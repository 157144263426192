import { axiosInstance } from '../Axios';
import {
	getData,
	setStage,
	storeData,
	toggleModal,
	toggleModalFetch,
	toggleModalMarkup,
} from './UiReducer';
import axios from 'axios';
const { createSlice } = require('@reduxjs/toolkit');

const HomeReducer = createSlice({
	name: 'home',
	initialState: {
		success: false,
		error: null,
		loading: false,
		loginInfo: (await getData('loginInfo')) ? await getData('loginInfo') : null,
		user: null,
		product: null,
		productdelete: null,
		addbanner: null,
		deletebanner: null,
		category: null,
		categorydelete: null,
		addcategory: null,
		createproduct: null,
		deleteproduct: null,
		addFund: null,
		debitFund: null,
		trans: null,
		search: null,
		bannerInfo: null,
		pdInfo: null,
		addpdInfo: null,
		partnermar: null,
		popdescdelete: null,
		sector: null,
		gallery: null,
		booking: null,
		downloadTicket: null,
		agenttrans: null,
		manageMarkup: null,
		manageMarkupUpdate: null,
		updateStatus: null,
		updateVerified: null,
		adminUpdate: null,
		tourdest: null,
		tourpackage: null,
		adminPassword: null,
		footerMenu: null,
		updateFooter: null,
		mail: null,
		userdelete: null,
		category: null,
		categorydelete: null,
		hero: null,
		herodelete: null,
		overview: null,
		overviewdelete: null,
		client: null,
		clientdelete: null,
		feedbackdelete: null,
		feedback: null,
		pc: null,
		pcdelete: null,
		faq: null,
		faqdelete: null,
		hrb: null,
		hrbdelete: null,
		categ: null,
		addproduct: null,
		pipeline: null,
		deletepipline: null,
		addpipeline: null,
		productC: null,
		productUpdate: null,
		pipelineUpdate: null,
		lead: null,
		leadCateg: null,
		task: null,
		addtask: null,
		task2: null,
		customer: null,
		adminTrans: null,
		fund: null,
		updateCustomer: null,
		custTrans: null,
		editCateg: null,
		fetchFund: null,
		transcredit: null,
		order: null,
		payment: null,
		changePay: null,
		orderPay: null,
		orderstatus: null,
		bank: null,
		changePassword: null,
		updateProfile: null,
		updatebank: null,
		staff: null,
		banner: null,
		addbanner: null,
		deleteCust: null,
		markup: null,
		markupupdate: null,
		notification: null,
		token: (await getData('token')) ? await getData('token') : null,
		deleteUser: null,
		features: null,
		featuresadd: null,
		adbanner: null,
		addAdBanner: null,
		bottomBanner: null,
		discountBanner: null,
		notify: null,
		addnotify: null,
		updatenotify: null,
	},
	reducers: {
		loginRequest(state, action) {
			state.loading = true;
		},
		loginSuccess(state, action) {
			state.loading = false;
			state.loginInfo = action.payload;
			storeData('loginInfo', action.payload);
			state.error = null;
		},
		loginFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		userRequest(state, action) {
			state.loading = true;
		},
		userSuccess(state, action) {
			state.loading = false;
			state.user = action.payload;
			state.error = null;
		},
		userFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		productRequest(state, action) {
			state.loading = true;
		},
		productSuccess(state, action) {
			state.loading = false;
			state.product = action.payload;
			state.error = null;
		},
		productFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		productdeleteRequest(state, action) {
			state.loading = true;
		},
		productdeleteSuccess(state, action) {
			state.loading = false;
			state.productdelete = action.payload;
			state.error = null;
		},
		productdeleteFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		categoryRequest(state, action) {
			state.loading = true;
		},
		categorySuccess(state, action) {
			state.loading = false;
			state.category = action.payload;
			state.error = null;
		},
		categoryFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		categorydeleteRequest(state, action) {
			state.loading = true;
		},
		categorydeleteSuccess(state, action) {
			state.loading = false;
			state.categorydelete = action.payload;
			state.error = null;
		},
		categorydeleteFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		pcRequest(state, action) {
			state.loading = true;
		},
		pcSuccess(state, action) {
			state.loading = false;
			state.pc = action.payload;
			state.error = null;
		},
		pcFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		pcdeleteRequest(state, action) {
			state.loading = true;
		},
		pcdeleteSuccess(state, action) {
			state.loading = false;
			state.pcdelete = action.payload;
			state.error = null;
		},
		pcdeleteFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		addcategoryRequest(state, action) {
			state.loading = true;
		},
		addcategorySuccess(state, action) {
			state.loading = false;
			state.categ = action.payload;
			state.error = null;
		},
		addcategoryFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		addproductRequest(state, action) {
			state.loading = true;
		},
		addproductSuccess(state, action) {
			state.loading = false;
			state.addproduct = action.payload;
			state.error = null;
		},
		addproductFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		pipelineRequest(state, action) {
			state.loading = true;
		},
		pipelineSuccess(state, action) {
			state.loading = false;
			state.pipeline = action.payload;
			state.error = null;
		},
		pipelineFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		pipelinedeleteRequest(state, action) {
			state.loading = true;
		},
		pipelinedeleteSuccess(state, action) {
			state.loading = false;
			state.deletepipline = action.payload;
			state.error = null;
		},
		pipelinedeleteFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		addpiplineRequest(state, action) {
			state.loading = true;
		},
		addpiplineSuccess(state, action) {
			state.loading = false;
			state.addpipeline = action.payload;
			state.error = null;
		},
		addpiplineFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		updateProductCRequest(state, action) {
			state.loading = true;
		},
		updateProductCSuccess(state, action) {
			state.loading = false;
			state.productC = action.payload;
			state.error = null;
		},
		updateProductCFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		updateProductRequest(state, action) {
			state.loading = true;
		},
		updateProductSuccess(state, action) {
			state.loading = false;
			state.productUpdate = action.payload;
			state.error = null;
		},
		updateProductFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		updatePipelineRequest(state, action) {
			state.loading = true;
		},
		updatePipelineSuccess(state, action) {
			state.loading = false;
			state.pipelineUpdate = action.payload;
			state.error = null;
		},
		updatePipelineFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		addleadRequest(state, action) {
			state.loading = true;
		},
		addleadSuccess(state, action) {
			state.loading = false;
			state.lead = action.payload;
			state.error = null;
		},
		addleadFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		leadcategRequest(state, action) {
			state.loading = true;
		},
		leadcategSuccess(state, action) {
			state.loading = false;
			state.leadCateg = action.payload;
			state.error = null;
		},
		leadcategFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		taskRequest(state, action) {
			state.loading = true;
		},
		taskSuccess(state, action) {
			state.loading = false;
			state.task = action.payload;
			state.error = null;
		},
		taskFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		addTaskRequest(state, action) {
			state.loading = true;
		},
		addTaskSuccess(state, action) {
			state.loading = false;
			state.addtask = action.payload;
			state.error = null;
		},
		addTaskFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		adminUpdateRequest(state, action) {
			state.loading = true;
		},
		adminUpdateSuccess(state, action) {
			state.loading = false;
			state.adminUpdate = action.payload;
			state.error = null;
		},
		adminUpdateFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		task2Request(state, action) {
			state.loading = true;
		},
		task2Success(state, action) {
			state.loading = false;
			state.task2 = action.payload;
			state.error = null;
		},
		task2Fail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		customerRequest(state, action) {
			state.loading = true;
		},
		customerSuccess(state, action) {
			state.loading = false;
			state.customer = action.payload;
			state.error = null;
		},
		customerFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		transRequest(state, action) {
			state.loading = true;
		},
		transSuccess(state, action) {
			state.loading = false;
			state.adminTrans = action.payload;
			state.error = null;
		},
		transFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		addFundRequest(state, action) {
			state.loading = true;
		},
		addFundSuccess(state, action) {
			state.loading = false;
			state.fund = action.payload;
			state.error = null;
		},
		addFundFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		updateCustRequest(state, action) {
			state.loading = true;
		},
		updateCustSuccess(state, action) {
			state.loading = false;
			state.updateCustomer = action.payload;
			state.error = null;
		},
		updateCustFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		custRequest(state, action) {
			state.loading = true;
		},
		custSuccess(state, action) {
			state.loading = false;
			state.custTrans = action.payload;
			state.error = null;
		},
		custFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		editcategoryRequest(state, action) {
			state.loading = true;
		},
		editcategorySuccess(state, action) {
			state.loading = false;
			state.editCateg = action.payload;
			state.error = null;
		},
		editcategoryFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		fetchFundRequest(state, action) {
			state.loading = true;
		},
		fetchFundSuccess(state, action) {
			state.loading = false;
			state.fetchFund = action.payload;
			state.error = null;
		},
		fetchFundFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		transCreditRequest(state, action) {
			state.loading = true;
		},
		transCreditSuccess(state, action) {
			state.loading = false;
			state.transcredit = action.payload;
			state.error = null;
		},
		transCreditFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		orderRequest(state, action) {
			state.loading = true;
		},
		orderSuccess(state, action) {
			state.loading = false;
			state.order = action.payload;
			state.error = null;
		},
		orderFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		paymentRequest(state, action) {
			state.loading = true;
		},
		paymentSuccess(state, action) {
			state.loading = false;
			state.payment = action.payload;
			state.error = null;
		},
		paymentFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		changePayRequest(state, action) {
			state.loading = true;
		},
		changePaySuccess(state, action) {
			state.loading = false;
			state.changePay = action.payload;
			state.error = null;
		},
		changePayFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		orderPayRequest(state, action) {
			state.loading = true;
		},
		orderPaySuccess(state, action) {
			state.loading = false;
			state.orderPay = action.payload;
			state.error = null;
		},
		orderPayFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		orderSRequest(state, action) {
			state.loading = true;
		},
		orderSSuccess(state, action) {
			state.loading = false;
			state.orderstatus = action.payload;
			state.error = null;
		},
		orderSFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		bankRequest(state, action) {
			state.loading = true;
		},
		bankSuccess(state, action) {
			state.loading = false;
			state.bank = action.payload;
			state.error = null;
		},
		bankFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		changePasswordRequest(state, action) {
			state.loading = true;
		},
		changePasswordSuccess(state, action) {
			state.loading = false;
			state.changePassword = action.payload;
			state.error = null;
		},
		changePasswordFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		updateProfileRequest(state, action) {
			state.loading = true;
		},
		updateProfileSuccess(state, action) {
			state.loading = false;
			state.updateProfile = action.payload;
			state.error = null;
		},
		updateProfileFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		updateBankRequest(state, action) {
			state.loading = true;
		},
		updateBankSuccess(state, action) {
			state.loading = false;
			state.updatebank = action.payload;
			state.error = null;
		},
		updateBankFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		staffRequest(state, action) {
			state.loading = true;
		},
		staffSuccess(state, action) {
			state.loading = false;
			state.staff = action.payload;
			state.error = null;
		},
		staffFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		bannerRequest(state, action) {
			state.loading = true;
		},
		bannerSuccess(state, action) {
			state.loading = false;
			state.banner = action.payload;
			state.error = null;
		},
		bannerFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		addbannerRequest(state, action) {
			state.loading = true;
		},
		addbannerSuccess(state, action) {
			state.loading = false;
			state.addbanner = action.payload;
			state.error = null;
		},
		addbannerFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		deleteCustomerRequest(state, action) {
			state.loading = true;
		},
		deleteCustomerSuccess(state, action) {
			state.loading = false;
			state.deleteCust = action.payload;
			state.error = null;
		},
		deleteCustomerFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		markupRequest(state, action) {
			state.loading = true;
		},
		markupSuccess(state, action) {
			state.loading = false;
			state.markup = action.payload;
			state.error = null;
		},
		markupFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		updatemarkupRequest(state, action) {
			state.loading = true;
		},
		updatemarkupSuccess(state, action) {
			state.loading = false;
			state.markupupdate = action.payload;
			state.error = null;
		},
		updatemarkupFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		notificationRequest(state, action) {
			state.loading = true;
		},
		notificationSuccess(state, action) {
			state.loading = false;
			state.notification = action.payload;
			state.error = null;
		},
		notificationFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		getTokenRequest(state, action) {
			state.loading = true;
		},
		getTokenSuccess(state, action) {
			state.loading = false;
			state.token = action.payload;
			storeData('token', action.payload);
			state.error = null;
		},
		getTokenFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		deleteUserRequest(state, action) {
			state.loading = true;
		},
		deleteUserSuccess(state, action) {
			state.loading = false;
			state.deleteUser = action.payload;
			state.error = null;
		},
		deleteUserFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		featuresRequest(state, action) {
			state.loading = true;
		},
		featuresSuccess(state, action) {
			state.loading = false;
			state.features = action.payload;
			state.error = null;
		},
		featuresFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		addfeaturesRequest(state, action) {
			state.loading = true;
		},
		addfeaturesSuccess(state, action) {
			state.loading = false;
			state.featuresadd = action.payload;
			state.error = null;
		},
		addfeaturesFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		adBannerRequest(state, action) {
			state.loading = true;
		},
		adBannerSuccess(state, action) {
			state.loading = false;
			state.adbanner = action.payload;
			state.error = null;
		},
		adBannerFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		addAdRequest(state, action) {
			state.loading = true;
		},
		addAdSuccess(state, action) {
			state.loading = false;
			state.addAdBanner = action.payload;
			state.error = null;
		},
		addAdFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		bottomBannerRequest(state, action) {
			state.loading = true;
		},
		bottomBannerSuccess(state, action) {
			state.loading = false;
			state.bottomBanner = action.payload;
			state.error = null;
		},
		bottomBannerFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		discountBannerRequest(state, action) {
			state.loading = true;
		},
		discountBannerSuccess(state, action) {
			state.loading = false;
			state.discountBanner = action.payload;
			state.error = null;
		},
		discountBannerFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		notifyRequest(state, action) {
			// state.loading = true;
		},
		notifySuccess(state, action) {
			state.loading = false;
			state.notify = action.payload;
			state.error = null;
		},
		notifyFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		addnotifyRequest(state, action) {
			state.loading = true;
		},
		addnotifySuccess(state, action) {
			state.loading = false;
			state.addnotify = action.payload;
			state.error = null;
		},
		addnotifyFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		updatenotifyRequest(state, action) {
			// state.loading = true;
		},
		updatenotifySuccess(state, action) {
			state.loading = false;
			state.updatenotify = action.payload;
			state.error = null;
		},
		updatenotifyFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
	},
});

const { actions } = HomeReducer;
const config = {
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
};
export const {
	loginRequest,
	loginSuccess,
	loginFail,
	userRequest,
	userSuccess,
	userFail,
	adminUpdateRequest,
	adminUpdateSuccess,
	adminUpdateFail,
	productRequest,
	productSuccess,
	productFail,
	productdeleteRequest,
	productdeleteSuccess,
	productdeleteFail,
	categoryRequest,
	categorySuccess,
	categoryFail,
	categorydeleteRequest,
	categorydeleteSuccess,
	categorydeleteFail,
	pcRequest,
	pcSuccess,
	pcFail,
	pcdeleteRequest,
	pcdeleteSuccess,
	pcdeleteFail,
	addcategoryRequest,
	addcategorySuccess,
	addcategoryFail,
	addproductRequest,
	addproductSuccess,
	addproductFail,
	pipelineRequest,
	pipelineSuccess,
	pipelineFail,
	pipelinedeleteRequest,
	pipelinedeleteSuccess,
	pipelinedeleteFail,
	addpiplineRequest,
	addpiplineSuccess,
	addpiplineFail,
	updateProductCRequest,
	updateProductCSuccess,
	updateProductCFail,
	updateProductRequest,
	updateProductSuccess,
	updateProductFail,
	updatePipelineRequest,
	updatePipelineSuccess,
	updatePipelineFail,
	addleadRequest,
	addleadSuccess,
	addleadFail,
	leadcategRequest,
	leadcategSuccess,
	leadcategFail,
	taskRequest,
	taskSuccess,
	taskFail,
	addTaskRequest,
	addTaskSuccess,
	addTaskFail,
	task2Request,
	task2Success,
	task2Fail,
	customerRequest,
	customerSuccess,
	customerFail,
	transRequest,
	transSuccess,
	transFail,
	addFundRequest,
	addFundSuccess,
	addFundFail,
	updateCustRequest,
	updateCustSuccess,
	updateCustFail,
	custRequest,
	custSuccess,
	custFail,
	editcategoryRequest,
	editcategorySuccess,
	editcategoryFail,
	fetchFundRequest,
	fetchFundSuccess,
	fetchFundFail,
	transCreditRequest,
	transCreditSuccess,
	transCreditFail,
	orderRequest,
	orderSuccess,
	orderFail,
	paymentRequest,
	paymentSuccess,
	paymentFail,
	changePayRequest,
	changePaySuccess,
	changePayFail,
	orderPayRequest,
	orderPaySuccess,
	orderPayFail,
	orderSRequest,
	orderSSuccess,
	orderSFail,
	bankRequest,
	bankSuccess,
	bankFail,
	changePasswordRequest,
	changePasswordSuccess,
	changePasswordFail,
	updateProfileRequest,
	updateProfileSuccess,
	updateProfileFail,
	updateBankRequest,
	updateBankSuccess,
	updateBankFail,
	staffRequest,
	staffSuccess,
	staffFail,
	bannerRequest,
	bannerSuccess,
	bannerFail,
	addbannerRequest,
	addbannerSuccess,
	addbannerFail,
	deleteCustomerRequest,
	deleteCustomerSuccess,
	deleteCustomerFail,
	markupRequest,
	markupSuccess,
	markupFail,
	updatemarkupRequest,
	updatemarkupSuccess,
	updatemarkupFail,
	notificationRequest,
	notificationSuccess,
	notificationFail,
	getTokenRequest,
	getTokenSuccess,
	getTokenFail,
	deleteUserRequest,
	deleteUserSuccess,
	deleteUserFail,
	featuresRequest,
	featuresSuccess,
	featuresFail,
	addfeaturesRequest,
	addfeaturesSuccess,
	addfeaturesFail,
	adBannerRequest,
	adBannerSuccess,
	adBannerFail,
	addAdRequest,
	addAdSuccess,
	addAdFail,
	bottomBannerRequest,
	bottomBannerSuccess,
	bottomBannerFail,
	discountBannerRequest,
	discountBannerSuccess,
	discountBannerFail,
	notifyRequest,
	notifySuccess,
	notifyFail,
	addnotifyRequest,
	addnotifySuccess,
	addnotifyFail,
	updatenotifyRequest,
	updatenotifySuccess,
	updatenotifyFail,
} = actions;

export const loginDispatch =
	(bodyData, setLoading, navigate) => async (dispatch) => {
		try {
			dispatch(loginRequest());
			const { data } = await axiosInstance.post(
				'/admin/login',
				bodyData,
				config
			);
			dispatch(loginSuccess(data));
			if (data.status === 'success') {
				navigate('/dashboard/default');
				dispatch(getTokenDispatch());
				setLoading(false);
			} else {
				setLoading(false);
				alert('Something went wrong');
			}
		} catch (error) {
			setLoading(false);
			alert(error.response.data.message);
			dispatch(
				loginFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const registerDispatch =
	(bodyData, navigate, Swal) => async (dispatch) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					// Authorization: `Bearer ${token}`,
				},
			};
			dispatch(userRequest());
			const { data } = await axiosInstance.post(
				'/customer/register',
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(userSuccess(data));
			if (data.status === 200) {
				navigate('/customer/list');
				Swal.fire({
					title: 'Customer Created Successfully!',
					icon: 'success',
					draggable: true,
				});
			}
		} catch (error) {
			const errors = error.response.data.errors || {}; // Default to an empty object if undefined
			const errorMessages = Object.values(errors).flat(); // Flatten nested arrays
			// alert(errorMessages ? errorMessages : error?.message);
			Swal.fire({
				title: 'Error!',
				text: errorMessages,
				icon: 'error',
				confirmButtonText: 'OK',
			});
			dispatch(
				userFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const adminregisterDispatch =
	(bodyData, navigate, Swal) => async (dispatch) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					// Authorization: `Bearer ${token}`,
				},
			};
			dispatch(userRequest());
			const { data } = await axiosInstance.post(
				'/admin/register',
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(userSuccess(data));
			if (data?.status === 200) {
				Swal.fire({
					title: 'Staff Created Successfully!',
					icon: 'success',
					draggable: true,
				});
				navigate('/staff/list');
			}
		} catch (error) {
			alert(error.response.data.message);
			dispatch(
				userFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const updateCustomerDispatch =
	(bodyData, navigate, custID, Swal, token) => async (dispatch) => {
		try {
			const config = {
				headers: {
					Accept: 'multipart/form-data',
				},
			};
			dispatch(updateCustRequest());
			const { data } = await axiosInstance.post(
				`/customer/update/${custID}`,
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(updateCustSuccess(data));
			if (data?.status === 'success') {
				Swal.fire({
					title: data?.message,
					icon: 'success',
					draggable: true,
				});
				dispatch(showcustomerDispatch());
				if (bodyData?.status === '1' || '0') {
					dispatch(showcustomerDispatch());
					// Swal.fire({
					// 	title: data?.message,
					// 	icon: 'success',
					// 	draggable: true,
					// });
				} else {
					// alert('Update Successfully');
					// Swal.fire({
					// 	title: data?.message,
					// 	icon: 'success',
					// 	draggable: true,
					// });
				}
				let form = {
					message: {
						token: custID?.token,
						notification: {
							body: 'This is an FCM notification message!',
							title: `Your ar become ${bodyData?.role}`,
						},
					},
				};
				dispatch(sendNotificationDispatch(form, token));
				navigate('/customer/list');
			}
		} catch (error) {
			// alert(error.response.data.message);
			dispatch(
				updateCustFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const leadMovementDispatch = (bodyData, token) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(adminUpdateRequest());
		const { data } = await axiosInstance.post(
			'/lead/leadmovementsave',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(adminUpdateSuccess(data));
	} catch (error) {
		alert(error.response.data.message);
		dispatch(
			adminUpdateFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const showcustomerDispatch = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				// Authorization: `Bearer ${token}`,
			},
		};
		dispatch(customerRequest());
		const { data } = await axiosInstance.get('/customer', config);
		console.log('data', data);
		dispatch(customerSuccess(data));
	} catch (error) {
		alert(error.response.data.message);
		dispatch(
			customerFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showTransDispatch = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				// Authorization: `Bearer ${token}`,
			},
		};
		dispatch(transRequest());
		const { data } = await axiosInstance.get('/admintrans', config);
		console.log('data', data);
		dispatch(transSuccess(data));
	} catch (error) {
		alert(error.response.data.message);
		dispatch(
			transFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showCustTransDispatch = (id) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(custRequest());
		const { data } = await axiosInstance.get(`/custtrans/${id}`, config);
		console.log('data', data);
		dispatch(custSuccess(data));
	} catch (error) {
		alert(error.response.data.message);
		dispatch(
			custFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const delteProductDispatch = (id, Swal) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(productdeleteRequest());
		const { data } = await axiosInstance.delete(`product/${id}`, config);
		console.log('data', data);
		dispatch(productdeleteSuccess(data));
		if (data) {
			Swal.fire({
				title: 'Deleted!',
				text: 'Your file has been deleted.',
				icon: 'success',
			});
			dispatch(showProductDispatch());
			// dispatch(showproductDispatch(bodyData2, token));
		}
	} catch (error) {
		alert(error.response.data.message);
		dispatch(
			productdeleteFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addProductDispatch =
	(bodyData, navigate, Swal) => async (dispatch) => {
		try {
			const config = {
				headers: {
					Accept: 'multipart/form-data',
				},
			};
			dispatch(addproductRequest());
			const { data } = await axiosInstance.post('/product', bodyData, config);
			console.log('data', data);
			dispatch(addproductSuccess(data));
			if (data.status === 201) {
				Swal.fire({
					title: data?.message,
					icon: 'success',
					draggable: true,
				});
				// alert('Product Upload Successfully');
				navigate('/product/list');
			}
		} catch (error) {
			if (error.response && error.response.data) {
				const errors = error.response.data.errors || {}; // Default to an empty object if undefined
				const errorMessages = Object.values(errors).flat(); // Flatten nested arrays
				Swal.fire({
					title: 'Error!',
					text: errorMessages,
					icon: 'error',
					confirmButtonText: 'OK',
				});
			} else {
				Swal.fire({
					title: 'Error!',
					text: error.message || 'An unexpected error occurred.',
					icon: 'error',
					confirmButtonText: 'OK',
				});
				// Handle other types of errors (e.g., network issues, timeouts)
				console.error(
					'Error:',
					error.message || 'An unexpected error occurred.'
				);
			}

			dispatch(
				addproductFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const showCategoryDispatch = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				// Authorization: `Bearer ${token}`,
			},
		};
		dispatch(categoryRequest());
		const { data } = await axiosInstance.get('/categories', config);
		console.log('data', data);

		dispatch(categorySuccess(data));
	} catch (error) {
		alert(error.response.data.message);
		dispatch(
			categoryFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addCategoryDispatch =
	(bodyData, navigate, Swal) => async (dispatch) => {
		try {
			const config = {
				headers: {
					// 'Content-Type': 'application/json',
					Accept: 'multipart/form-data',
					// Authorization: `Bearer ${token}`,
				},
			};
			dispatch(addcategoryRequest());
			const { data } = await axiosInstance.post(
				'/categories',
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(addcategorySuccess(data));
			if (data?.status === 201) {
				// alert('Category Added Successfully');
				Swal.fire({
					title: data?.message,
					icon: 'success',
					draggable: true,
				});
				navigate('/category/list');
			}
		} catch (error) {
			alert(error.response.data.message);
			dispatch(
				addcategoryFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const editCategoryDispatch =
	(bodyData, navigate, id, Swal) => async (dispatch) => {
		try {
			const config = {
				headers: {
					// 'Content-Type': 'application/json',
					Accept: 'multipart/form-data',
					// Authorization: `Bearer ${token}`,
				},
			};
			dispatch(editcategoryRequest());
			const { data } = await axiosInstance.post(
				`/categories/${id}`,
				bodyData,
				config
			);
			console.log('data', data);

			dispatch(editcategorySuccess(data));
			if (data?.status === 200) {
				// alert('Category Update Successfully');
				Swal.fire({
					title: data?.message,
					icon: 'success',
					draggable: true,
				});
				navigate('/category/list');
			}
		} catch (error) {
			alert(error.response.data.message);
			dispatch(
				editcategoryFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const delteCategoryDispatch = (id, Swal) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};
		dispatch(categorydeleteRequest());
		const { data } = await axiosInstance.delete(`/categories/${id}`, config);
		console.log('data', data);
		dispatch(categorydeleteSuccess(data));
		if (data) {
			Swal.fire({
				title: 'Deleted!',
				text: 'Your file has been deleted.',
				icon: 'success',
			});
			dispatch(showCategoryDispatch());
		}
	} catch (error) {
		alert(error.response.data.message);
		dispatch(
			categorydeleteFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const showpcDispatch = (token) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(pcRequest());
		const { data } = await axiosInstance.get('/pc', config);
		console.log('data', data);
		dispatch(pcSuccess(data));
	} catch (error) {
		alert(error.response.data.message);
		dispatch(
			pcFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const deltepcDispatch = (token, id) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(pcdeleteRequest());
		const { data } = await axiosInstance.delete(`/pc/${id}`, config);
		console.log('data', data);
		dispatch(pcdeleteSuccess(data));
		if (data) {
			dispatch(showpcDispatch(token));
		}
	} catch (error) {
		alert(error.response.data.message);
		dispatch(
			pcdeleteFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showpipelineDispatch = (bodyData, token) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(pipelineRequest());
		const { data } = await axiosInstance.post(
			'/pipeline/pipelinelist',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(pipelineSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			pipelineFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const deltePipelineDispatch =
	(bodyData, token, bodyData2) => async (dispatch) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: `Bearer ${token}`,
				},
			};
			dispatch(pipelinedeleteRequest());
			const { data } = await axiosInstance.post(
				`/pipeline/pipelinedelete`,
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(pipelinedeleteSuccess(data));
			if (data) {
				dispatch(showpipelineDispatch(bodyData2, token));
			}
		} catch (error) {
			console.log('error', error);
			dispatch(
				pipelinedeleteFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const addPiplineDispatch =
	(bodyData, token, navigate) => async (dispatch) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: `Bearer ${token}`,
				},
			};
			dispatch(addpiplineRequest());
			const { data } = await axiosInstance.post(
				'/pipeline/pipelinesave',
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(addpiplineSuccess(data));
			if (data?.statusCode === 200) {
				navigate('/pipeline/list');
			}
		} catch (error) {
			console.log('error', error);
			dispatch(
				addpiplineFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const updateProductCDispatch =
	(bodyData, token, form2) => async (dispatch) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: `Bearer ${token}`,
				},
			};
			dispatch(updateProductCRequest());
			const { data } = await axiosInstance.post(
				'/product/productcategoryupdate',
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(updateProductCSuccess(data));
			if (data?.statusCode === 200) {
				dispatch(showCategoryDispatch(form2, token));
				// navigate('/dashboard/pipelinelist');
			}
		} catch (error) {
			console.log('error', error);
			dispatch(
				updateProductCFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const updateProductDispatch =
	(bodyData, navigate, id, Swal) => async (dispatch) => {
		try {
			const config = {
				headers: {
					Accept: 'multipart/form-data',
					// Authorization: `Bearer ${token}`,
				},
			};
			dispatch(updateProductRequest());
			const { data } = await axiosInstance.post(
				`/product/${id}`,
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(updateProductSuccess(data));
			if (data.status === 200) {
				Swal.fire({
					title: data?.message,
					icon: 'success',
					draggable: true,
				});
				navigate('/product/list');
			} else {
				alert('Failed');
			}
		} catch (error) {
			console.log('error', error);
			dispatch(
				updateProductFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const updatePipelineDispatch =
	(bodyData, token, navigate) => async (dispatch) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: `Bearer ${token}`,
				},
			};
			dispatch(updatePipelineRequest());
			const { data } = await axiosInstance.post(
				'/pipeline/pipelineupdate',
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(updatePipelineSuccess(data));
			if (data) {
				// dispatch(showCategoryDispatch(form2, token));
				navigate('/pipeline/list');
			}
		} catch (error) {
			console.log('error', error);
			dispatch(
				updatePipelineFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const addLeadsDispatch =
	(bodyData, token, navigate, formm) => async (dispatch) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: `Bearer ${token}`,
				},
			};
			dispatch(addleadRequest());
			const { data } = await axiosInstance.post(
				'/lead/leadtranssave',
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(addleadSuccess(data));
			if (data) {
				// dispatch(pipelineLeadListDispatch(formm, token));
				navigate('/board/list', {
					state: { id: formm?.pipelineDto.pipeLineId },
				});
			}
		} catch (error) {
			console.log('error', error);
			dispatch(
				addleadFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const LeadsCategDispatch = (bodyData, token) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(leadcategRequest());
		const { data } = await axiosInstance.post(
			'/lead/leadcategorylist',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(leadcategSuccess(data));
		if (data) {
		}
	} catch (error) {
		console.log('error', error);
		dispatch(
			leadcategFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const TaskTranListDispatch = (bodyData, token) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(taskRequest());
		const { data } = await axiosInstance.post(
			'/task/tasktranslist',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(taskSuccess(data));
		if (data) {
		}
	} catch (error) {
		console.log('error', error);
		dispatch(
			taskFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const TaskListDispatch = (bodyData, token) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(task2Request());
		const { data } = await axiosInstance.post(
			'/task/tasklist',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(task2Success(data));
		if (data) {
		}
	} catch (error) {
		console.log('error', error);
		dispatch(
			task2Fail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addTaskDispatch = (bodyData, token, form2) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(addTaskRequest());
		const { data } = await axiosInstance.post(
			'/task/tasktranssave',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(addTaskSuccess(data));
		if (data) {
			dispatch(TaskTranListDispatch(form2, token));
		}
	} catch (error) {
		console.log('error', error);
		dispatch(
			addTaskFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addFundDispatch = (bodyData, Swal) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				// Authorization: `Bearer ${token}`,
			},
		};
		dispatch(addFundRequest());
		const { data } = await axiosInstance.post(
			'/customer/addfund',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(addFundSuccess(data));
		if (data.success) {
			Swal.fire({
				title: `₹ ${bodyData?.amount} Credit added Successfully`,
				icon: 'success',
				draggable: true,
			});
			dispatch(showcustomerDispatch());
		} else {
			// Swal.fire({
			// 	title: `${data?.message}`,
			// 	icon: 'success',
			// 	draggable: true,
			// });
			Swal.fire({
				title: 'Error!',
				text: data.message || 'An unexpected error occurred.',
				icon: 'error',
				confirmButtonText: 'OK',
			});
		}
	} catch (error) {
		console.log('error', error);
		dispatch(
			addFundFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showProductDispatch = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(productRequest());
		const { data } = await axiosInstance.get('/adminproduct', config);
		console.log('data', data);
		dispatch(productSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			productFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fetchFundDispatch = (bodyData, Swal) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				// Authorization: `Bearer ${token}`,
			},
		};
		dispatch(fetchFundRequest());
		const { data } = await axiosInstance.post(
			'/customer/fetchfund',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(fetchFundSuccess(data));
		if (data?.status === 200) {
			Swal.fire({
				title: `₹ ${bodyData?.amount} debited Successfully!`,
				icon: 'success',
				draggable: true,
			});
			dispatch(showcustomerDispatch());
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: data.message,
			});
			// alert(data.message);
		}
	} catch (error) {
		console.log('error', error);
		dispatch(
			fetchFundFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showTransCreditDispatch = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				// Authorization: `Bearer ${token}`,
			},
		};
		dispatch(transCreditRequest());
		const { data } = await axiosInstance.get('/admintranscredit', config);
		console.log('data', data);
		dispatch(transCreditSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			transCreditFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showOrderDispatch = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(orderRequest());
		const { data } = await axiosInstance.get('/orderAll', config);
		console.log('data', data);
		dispatch(orderSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			orderFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showPaymentDispatch = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(paymentRequest());
		const { data } = await axiosInstance.get('/payment', config);
		console.log('data', data);
		dispatch(paymentSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			paymentFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const ChangePaymentDispatch =
	(bodyData, id, Swal) => async (dispatch) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
			};
			dispatch(changePayRequest());
			const { data } = await axiosInstance.put(
				`/payments/${id}/status`,
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(changePaySuccess(data));
			if (data) {
				Swal.fire({
					title: 'Status Updated Successfully!',
					icon: 'success',
					draggable: true,
				});
				dispatch(showPaymentDispatch());
			}
		} catch (error) {
			console.log('error', error);
			dispatch(
				changePayFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const orderPaymentDispatch =
	(bodyData, id, Swal) => async (dispatch) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
			};
			dispatch(orderPayRequest());
			const { data } = await axiosInstance.put(
				`/orders/${id}/payment-status`,
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(orderPaySuccess(data));
			if (data?.success) {
				Swal.fire({
					title: 'Status Updated Successfully!',
					icon: 'success',
					draggable: true,
				});
				dispatch(showOrderDispatch());
			}
		} catch (error) {
			console.log('error', error);
			dispatch(
				orderPayFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const orderStatusDispatch = (bodyData, id, Swal) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(orderSRequest());
		const { data } = await axiosInstance.put(
			`/orders/${id}/booking-status`,
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(orderSSuccess(data));
		if (data?.success) {
			Swal.fire({
				title: 'Status Updated Successfully!',
				icon: 'success',
				draggable: true,
			});
			dispatch(showOrderDispatch());
		}
	} catch (error) {
		console.log('error', error);
		dispatch(
			orderSFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showBankDispatch = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(bankRequest());
		const { data } = await axiosInstance.get('/bankdetails', config);
		console.log('data', data);
		dispatch(bankSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			bankFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const changePasswordDispatch = (bodyData, Swal) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(changePasswordRequest());
		const { data } = await axiosInstance.post(
			'/admin/changePassword',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(changePasswordSuccess(data));
		if (data?.status) {
			Swal.fire({
				title: 'Password Change Successfully!',
				icon: 'success',
				draggable: true,
			});
		} else {
			Swal.fire({
				title: 'Error!',
				text: data?.message,
				icon: 'error',
				confirmButtonText: 'OK',
			});
		}
	} catch (error) {
		const errors = error.response.data.errors || {}; // Default to an empty object if undefined
		const errorMessages = Object.values(errors).flat(); // Flatten nested arrays
		// alert(errorMessages ? errorMessages : error?.message);
		Swal.fire({
			title: 'Error!',
			text: errorMessages,
			icon: 'error',
			confirmButtonText: 'OK',
		});
		dispatch(
			changePasswordFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const updateProfileDispatch =
	(bodyData, id, Swal) => async (dispatch) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
			};
			dispatch(updateProfileRequest());
			const { data } = await axiosInstance.put(
				`/admin/update/${id}`,
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(updateProfileSuccess(data));
			dispatch(showStaffDispatch());
			if (data?.status) {
				Swal.fire({
					title: 'Profile Updated Successfully!',
					icon: 'success',
					draggable: true,
				});
			}
		} catch (error) {
			console.log('error', error);
			dispatch(
				updateProfileFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const updateBankDispatch =
	(bodyData, navigate, id, Swal) => async (dispatch) => {
		try {
			const config = {
				headers: {
					Accept: 'multipart/form-data',
				},
			};
			dispatch(updateBankRequest());
			const { data } = await axiosInstance.post(
				`/bankdetails/${id}`,
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(updateBankSuccess(data));
			if (data) {
				navigate('/bank/list');
				Swal.fire({
					title: 'Bank Detail Updated Successfully!',
					icon: 'success',
					draggable: true,
				});
			}
		} catch (error) {
			console.log('error', error);
			dispatch(
				updateBankFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const showStaffDispatch = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(staffRequest());
		const { data } = await axiosInstance.get('/admin/staff', config);
		console.log('data', data);
		dispatch(staffSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			staffFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addFundSecurityDispatch = (bodyData, Swal) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				// Authorization: `Bearer ${token}`,
			},
		};
		dispatch(addFundRequest());
		const { data } = await axiosInstance.post(
			'/customer/addfundsecurity',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(addFundSuccess(data));
		if (data?.success) {
			Swal.fire({
				title: 'Security Balance Updated Successfully!',
				icon: 'success',
				draggable: true,
			});
			dispatch(showcustomerDispatch());
		}
	} catch (error) {
		console.log('error', error);
		dispatch(
			addFundFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const bannerDispatch = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(bannerRequest());
		const { data } = await axiosInstance.get('/banners', config);
		console.log('data', data);
		dispatch(bannerSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			bannerFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addbannerDispatch = (bodyData, navigate) => async (dispatch) => {
	try {
		const config = {
			headers: {
				Accept: 'multipart/form-data',
			},
		};
		dispatch(addbannerRequest());
		const { data } = await axiosInstance.post('/banners', bodyData, config);
		console.log('data', data);
		dispatch(addbannerSuccess(data));
		if (data) {
			navigate('/banner/list');
		}
	} catch (error) {
		console.log('error', error);
		dispatch(
			addbannerFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const deltebannerDispatch = (id, Swal) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(categorydeleteRequest());
		const { data } = await axiosInstance.delete(`/banner/${id}`, config);
		console.log('data', data);
		dispatch(categorydeleteSuccess(data));
		if (data) {
			Swal.fire({
				title: 'Deleted!',
				text: 'Your file has been deleted.',
				icon: 'success',
			});
			dispatch(bannerDispatch());
		}
	} catch (error) {
		alert(error.response.data.message);
		dispatch(
			categorydeleteFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const delteCustomerDispatch = (id, Swal) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(deleteCustomerRequest());
		const { data } = await axiosInstance.delete(`/customer/${id}`, config);
		console.log('data', data);
		dispatch(deleteCustomerSuccess(data));
		if (data?.status === 'success') {
			Swal.fire({
				title: 'Deleted!',
				text: 'Your Customer has been deleted.',
				icon: 'success',
			});
			dispatch(showcustomerDispatch());
		}
	} catch (error) {
		alert(error.response.data.message);
		dispatch(
			deleteCustomerFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const delteUserDispatch = (id, Swal) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(deleteUserRequest());
		const { data } = await axiosInstance.delete(`/admin/${id}`, config);
		console.log('data', data);
		dispatch(deleteUserSuccess(data));
		if (data?.status === 200) {
			Swal.fire({
				title: 'Deleted!',
				text: 'Your User has been deleted.',
				icon: 'success',
			});
			dispatch(showStaffDispatch());
		}
	} catch (error) {
		alert(error.response.data.message);
		dispatch(
			deleteUserFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const markupDispatch = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(markupRequest());
		const { data } = await axiosInstance.get('/markup', config);
		console.log('data', data);
		dispatch(markupSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			markupFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const updatemarkupDispatch =
	(bodyData, id, Swal) => async (dispatch) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
			};
			dispatch(updatemarkupRequest());
			const { data } = await axiosInstance.put(
				`/markup/${id}`,
				bodyData,
				config
			);
			console.log('data', data);
			if (data.status === 200) {
				Swal.fire({
					title: 'Markup & GST Updated Successfully!',
					icon: 'success',
					draggable: true,
				});
			}
			dispatch(updatemarkupSuccess(data));
		} catch (error) {
			console.log('error', error);
			dispatch(
				updatemarkupFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const getTokenDispatch = () => async (dispatch) => {
	try {
		dispatch(getTokenRequest());
		const { data } = await axiosInstance.get(`/token`, config);
		console.log('data', data);
		dispatch(getTokenSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			getTokenFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const sendNotificationDispatch =
	(bodyData, Swal) => async (dispatch) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
					// Authorization: `Bearer ${token?.access_token}`,
				},
			};
			dispatch(notificationRequest());
			const { data } = await axiosInstance.post(
				`https://api.zero7shirts.com/api/fcmnotification`,
				bodyData,
				config
			);
			console.log('data', data);
			Swal.fire({
				position: 'top-end',
				icon: 'success',
				title: 'Notification Send Successfully',
				showConfirmButton: false,
				timer: 1500,
			});
			dispatch(notificationSuccess(data));
		} catch (error) {
			console.log('error', error);
			dispatch(
				notificationFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const featuresDispatch = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(featuresRequest());
		const { data } = await axiosInstance.get('/features', config);
		console.log('data', data);
		dispatch(featuresSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			featuresFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addfeaturesDispatch =
	(bodyData, navigate, Swal) => async (dispatch) => {
		try {
			const config = {
				headers: {
					Accept: 'multipart/form-data',
				},
			};
			dispatch(addfeaturesRequest());
			const { data } = await axiosInstance.post('/features', bodyData, config);
			console.log('data', data);
			dispatch(addfeaturesSuccess(data));
			if (data) {
				navigate('/feature/list');
				Swal.fire({
					title: 'Features Created Successfully!',
					icon: 'success',
					draggable: true,
				});
			}
		} catch (error) {
			console.log('error', error);
			dispatch(
				addfeaturesFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const updateFeaturesBannerDispatch =
	(bodyData, id, navigate, Swal) => async (dispatch) => {
		try {
			const config = {
				headers: {
					Accept: 'multipart/form-data',
				},
			};
			dispatch(addAdRequest());
			const { data } = await axiosInstance.post(
				`/features/${id}`,
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(addAdSuccess(data));
			if (data) {
				navigate('/feature/list');
				Swal.fire({
					title: 'Features Update Successfully!',
					icon: 'success',
					draggable: true,
				});
			}
		} catch (error) {
			console.log('error', error);
			dispatch(
				addAdFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const deltefeatureDispatch = (id, Swal) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(categorydeleteRequest());
		const { data } = await axiosInstance.delete(`/features/${id}`, config);
		console.log('data', data);
		dispatch(categorydeleteSuccess(data));
		if (data) {
			Swal.fire({
				title: 'Deleted!',
				text: 'Your file has been deleted.',
				icon: 'success',
			});
			dispatch(featuresDispatch());
		}
	} catch (error) {
		alert(error.response.data.message);
		dispatch(
			categorydeleteFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const adBannerDispatch = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(adBannerRequest());
		const { data } = await axiosInstance.get('/ad', config);
		console.log('data', data);
		dispatch(adBannerSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			adBannerFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addAdbannerDispatch =
	(bodyData, navigate, Swal) => async (dispatch) => {
		try {
			const config = {
				headers: {
					Accept: 'multipart/form-data',
				},
			};
			dispatch(addAdRequest());
			const { data } = await axiosInstance.post('/ad', bodyData, config);
			console.log('data', data);
			dispatch(addAdSuccess(data));
			if (data) {
				navigate('/ads/list');
				Swal.fire({
					title: 'Ad Banner Added Successfully!',
					icon: 'success',
					draggable: true,
				});
			}
		} catch (error) {
			console.log('error', error);
			dispatch(
				addAdFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const updateAdBannerDispatch =
	(bodyData, id, navigate, Swal) => async (dispatch) => {
		try {
			const config = {
				headers: {
					Accept: 'multipart/form-data',
				},
			};
			dispatch(addAdRequest());
			const { data } = await axiosInstance.post(`/ad/${id}`, bodyData, config);
			console.log('data', data);
			dispatch(addAdSuccess(data));
			if (data) {
				navigate('/ads/list');
				Swal.fire({
					title: 'Ads Banner Update Successfully!',
					icon: 'success',
					draggable: true,
				});
			}
		} catch (error) {
			console.log('error', error);
			dispatch(
				addAdFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const delteAdDispatch = (id, Swal) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(categorydeleteRequest());
		const { data } = await axiosInstance.delete(`/ad/${id}`, config);
		console.log('data', data);
		dispatch(categorydeleteSuccess(data));
		if (data) {
			Swal.fire({
				title: 'Deleted!',
				text: 'Your file has been deleted.',
				icon: 'success',
			});
			dispatch(adBannerDispatch());
		}
	} catch (error) {
		alert(error.response.data.message);
		dispatch(
			categorydeleteFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const bottomBannerDispatch = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(bottomBannerRequest());
		const { data } = await axiosInstance.get('/bottom', config);
		console.log('data', data);
		dispatch(bottomBannerSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			bottomBannerFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addBottomBannerDispatch =
	(bodyData, navigate, Swal) => async (dispatch) => {
		try {
			const config = {
				headers: {
					Accept: 'multipart/form-data',
				},
			};
			dispatch(addAdRequest());
			const { data } = await axiosInstance.post('/bottom', bodyData, config);
			console.log('data', data);
			dispatch(addAdSuccess(data));
			if (data) {
				navigate('/bottombanner/list');
				Swal.fire({
					title: 'Banner Added Successfully!',
					icon: 'success',
					draggable: true,
				});
			}
		} catch (error) {
			console.log('error', error);
			dispatch(
				addAdFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const updateBottomBannerDispatch =
	(bodyData, id, navigate, Swal) => async (dispatch) => {
		try {
			const config = {
				headers: {
					Accept: 'multipart/form-data',
				},
			};
			dispatch(addAdRequest());
			const { data } = await axiosInstance.post(
				`/bottom/${id}`,
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(addAdSuccess(data));
			if (data) {
				navigate('/bottombanner/list');
				Swal.fire({
					title: 'Bottom Banner Update Successfully!',
					icon: 'success',
					draggable: true,
				});
			}
		} catch (error) {
			console.log('error', error);
			dispatch(
				addAdFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const delteBottomDispatch = (id, Swal) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(categorydeleteRequest());
		const { data } = await axiosInstance.delete(`/bottom/${id}`, config);
		console.log('data', data);
		dispatch(categorydeleteSuccess(data));
		if (data) {
			Swal.fire({
				title: 'Deleted!',
				text: 'Your file has been deleted.',
				icon: 'success',
			});
			dispatch(bottomBannerDispatch());
		}
	} catch (error) {
		alert(error.response.data.message);
		dispatch(
			categorydeleteFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const discountBannerDispatch = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(discountBannerRequest());
		const { data } = await axiosInstance.get('/single', config);
		console.log('data', data);
		dispatch(discountBannerSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			discountBannerFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const updateDiscountBannerDispatch =
	(bodyData, id, navigate, Swal) => async (dispatch) => {
		try {
			const config = {
				headers: {
					Accept: 'multipart/form-data',
				},
			};
			dispatch(addAdRequest());
			const { data } = await axiosInstance.post(
				`/single/${id}`,
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(addAdSuccess(data));
			if (data) {
				navigate('/discountbanner/list');
				Swal.fire({
					title: 'Discount Banner Update Successfully!',
					icon: 'success',
					draggable: true,
				});
			}
		} catch (error) {
			console.log('error', error);
			dispatch(
				addAdFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const notificationDispatch = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(notifyRequest());
		const { data } = await axiosInstance.get('/notifications', config);
		console.log('data', data);
		dispatch(notifySuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			notifyFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const deltenotificationDispatch = (id) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		};
		dispatch(categorydeleteRequest());
		const { data } = await axiosInstance.delete(`/notifications/${id}`, config);
		console.log('data', data);
		dispatch(categorydeleteSuccess(data));
		if (data) {
			dispatch(notificationDispatch());
		}
	} catch (error) {
		// alert(error.response.data.message);
		dispatch(
			categorydeleteFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addCustomernotificationDispatch =
	(bodyData) => async (dispatch) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
			};
			dispatch(addnotifyRequest());
			const { data } = await axiosInstance.post(
				'/notification',
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(addnotifySuccess(data));
		} catch (error) {
			console.log('error', error);
			dispatch(
				addnotifyFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const updateStatusnotificationDispatch =
	(id, form) => async (dispatch) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
			};
			dispatch(updatenotifyRequest());
			const { data } = await axiosInstance.put(
				`/notifications/${id}/status`,
				form,
				config
			);
			console.log('data', data);
			dispatch(updatenotifySuccess(data));
		} catch (error) {
			console.log('error', error);
			dispatch(
				updatenotifyFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const fetchFundSecurityDispatch =
	(bodyData, Swal) => async (dispatch) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					// Authorization: `Bearer ${token}`,
				},
			};
			dispatch(fetchFundRequest());
			const { data } = await axiosInstance.post(
				'/customer/fetchFundsSecurity',
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(fetchFundSuccess(data));
			if (data?.status === 200) {
				Swal.fire({
					title: `₹ ${bodyData?.amount} debited Successfully!`,
					icon: 'success',
					draggable: true,
				});
				dispatch(showcustomerDispatch());
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: data.message,
				});
				// alert(data.message);
			}
		} catch (error) {
			console.log('error', error);
			dispatch(
				fetchFundFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export default HomeReducer;
