const { createSlice } = require('@reduxjs/toolkit');
export const storeData = async (key, value) => {
	try {
		const now = new Date();
		const dataWithExpiry = {
			value: value,
			expiry: now.getTime() + 30 * 60 * 1000, // Convert minutes to milliseconds
		};
		const jsonValue = JSON.stringify(dataWithExpiry);
		localStorage.setItem(key, jsonValue);
	} catch (e) {
		console.error('Error saving data:', e); // Handle saving error
	}
};

export const getData = async (key) => {
	try {
		const jsonValue = localStorage.getItem(key);
		// console.log('jsonValue', jsonValue);
		if (!jsonValue) {
			return null; // Key not found
		}
		const dataWithExpiry = JSON.parse(jsonValue);
		console.log('dataWithExpiry', dataWithExpiry);
		const now = new Date();
		if (now.getTime() > dataWithExpiry.expiry) {
			// Data has expired
			localStorage.removeItem(key);
			// console.log('55', dataWithExpiry);
			return null;
		}
		return dataWithExpiry.value; // Return the value if not expired
	} catch (e) {
		console.error('Error reading data:', e); // Handle read error
		return null;
	}
};
const UiReducer = createSlice({
	name: 'ui',
	initialState: {
		tab: '',
		orderTab: '',
		modal: false,
		agentId: null,
		modalFetch: false,
		modalmarkup: false,
		modalprod: false,
		modalpipe: false,
		cart: [],
		won: [],
		lost: [],
		custom: [],
		modalprodUC: false,
		upc: null,
		modalUP: false,
		up: null,
		stage: null,
		custID: null,
		pipeline: null,
		lead: null,
		PayID: null,
	},
	reducers: {
		setTab(state, action) {
			state.tab = action.payload;
		},
		setOrderTab(state, action) {
			state.orderTab = action.payload;
		},
		setPayID(state, action) {
			state.PayID = action.payload;
		},
		setCustId(state, action) {
			state.custID = action.payload;
		},
		setPipelineId(state, action) {
			state.pipeline = action.payload;
		},
		setStage(state, action) {
			state.stage = action.payload;
		},
		setLead(state, action) {
			state.lead = action.payload;
		},
		setUPC(state, action) {
			state.upc = action.payload;
		},
		setUP(state, action) {
			state.up = action.payload;
		},
		toggleModal(state, action) {
			state.modal = action.payload;
		},
		toggleUpdateProductModal(state, action) {
			state.modalUP = action.payload;
		},
		toggleUProductCModal(state, action) {
			state.modalprodUC = action.payload;
		},
		toggleProductModal(state, action) {
			state.modalprod = action.payload;
		},
		togglePipelineModal(state, action) {
			state.modalpipe = action.payload;
		},
		setID(state, action) {
			state.agentId = action.payload;
		},
		toggleModalFetch(state, action) {
			state.modalFetch = action.payload;
		},
		toggleModalMarkup(state, action) {
			state.modalmarkup = action.payload;
		},
		addCartRow(state, action) {
			if (action.payload === null) {
				state.cart = [];
			} else {
				const itemExists = state.cart.some(
					(item) =>
						item.stageName.toLowerCase() ===
						action.payload.stageName.toLowerCase()
				); // assuming each item has a unique 'id'

				if (!itemExists) {
					state.cart.push(action.payload);
				}
			}
		},
		addWonRow(state, action) {
			if (action.payload === null) {
				state.won = [];
			} else {
				const itemExists = state.won.some(
					(item) =>
						item.stageName.toLowerCase() ===
						action.payload.stageName.toLowerCase()
				); // assuming each item has a unique 'id'

				if (!itemExists) {
					state.won.push(action.payload);
				}
			}
		},
		addLostRow(state, action) {
			if (action.payload === null) {
				state.lost = [];
			} else {
				const itemExists = state.lost.some(
					(item) =>
						item.stageName.toLowerCase() ===
						action.payload.stageName.toLowerCase()
				); // assuming each item has a unique 'id'

				if (!itemExists) {
					state.lost.push(action.payload);
				}
			}
		},
		addCustom(state, action) {
			state.custom = action.payload;
		},
		addCart(state, action) {
			state.cart = action.payload;
		},
		addWon(state, action) {
			state.won = action.payload;
		},
		addLost(state, action) {
			state.lost = action.payload;
		},
		addCustomRow(state, action) {
			if (action.payload === null) {
				state.custom = [];
			} else {
				const itemExists = state.custom.some(
					(item) =>
						item.name.toLowerCase() === action.payload.name.toLowerCase()
				); // assuming each item has a unique 'id'

				if (!itemExists) {
					state.custom.push(action.payload);
				}
			}
		},
		clearCartRow(state, action) {
			state.cart = [];
		},
		removeSelectedCart(state, action) {
			state.cart = state.cart.filter(
				(row) =>
					row.stageName.toLowerCase() !== action.payload.stageName.toLowerCase()
			); // assuming 'id' is unique
		},
		removeSelectedWon(state, action) {
			state.won = state.won.filter(
				(row) =>
					row.stageName.toLowerCase() !== action.payload.stageName.toLowerCase()
			); // assuming 'id' is unique
		},
		removeSelectedLost(state, action) {
			state.lost = state.lost.filter(
				(row) =>
					row.stageName.toLowerCase() !== action.payload.stageName.toLowerCase()
			); // assuming 'id' is unique
		},
		removeSelectedCustom(state, action) {
			state.custom = state.custom.filter(
				(row) => row.name.toLowerCase() !== action.payload.name.toLowerCase()
			); // assuming 'id' is unique
		},
	},
});

const { actions } = UiReducer;

export const {
	setTab,
	toggleModal,
	setID,
	addCustom,
	toggleModalFetch,
	toggleModalMarkup,
	addCartRow,
	addWonRow,
	addLostRow,
	clearCartRow,
	removeSelectedCart,
	removeSelectedWon,
	removeSelectedLost,
	addCustomRow,
	removeSelectedCustom,
	toggleProductModal,
	togglePipelineModal,
	toggleUProductCModal,
	setUPC,
	toggleUpdateProductModal,
	setUP,
	addCart,
	addLost,
	addWon,
	setStage,
	setCustId,
	setPipelineId,
	setLead,
	setPayID,
	setOrderTab,
} = actions;

export default UiReducer;
