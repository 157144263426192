import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

// const Analytics = Loadable(lazy(() => import('./CustomerSection')));
const ProductList = Loadable(lazy(() => import('./TransList')));
const CreditList = Loadable(lazy(() => import('./CreditList')));
const PaymentList = Loadable(lazy(() => import('./PaymentList')));

const TransRoutes = [
	{
		path: '/payment/list',
		element: <PaymentList />,
		auth: authRoles.admin,
	},
	{
		path: '/trans/list',
		element: <ProductList />,
		auth: authRoles.admin,
	},
	{
		path: '/trans/credit',
		element: <CreditList />,
		auth: authRoles.admin,
	},
	// {
	// 	path: '/pipeline/edit',
	// 	element: <EditPipeline />,
	// 	auth: authRoles.admin,
	// },
];

export default TransRoutes;
