import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const PendingList = Loadable(lazy(() => import('./PendingList')));
const ConfirmList = Loadable(lazy(() => import('./ConfirmList')));
const DispatchList = Loadable(lazy(() => import('./OrderDetails')));
const RejectList = Loadable(lazy(() => import('./RejectList')));
const DeliverList = Loadable(lazy(() => import('./DeliveredList')));

const OrderRoutes = [
	{
		path: '/order/list',
		element: <PendingList />,
		auth: authRoles.admin,
	},
	{
		path: '/order/list/details',
		element: <DispatchList />,
		auth: authRoles.admin,
	},
	{
		path: '/rejected/list',
		element: <RejectList />,
		auth: authRoles.admin,
	},
	{
		path: '/dispatch/list',
		element: <DispatchList />,
		auth: authRoles.admin,
	},
	{
		path: '/delivered/list',
		element: <DeliverList />,
		auth: authRoles.admin,
	},
];

export default OrderRoutes;
