import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './auth/AuthGuard';
import { authRoles } from './auth/authRoles';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import CanbanBoardRoutes from './views/CanbanBoard/CanbanBoardRoutes';
import ProductRoutes from './views/product/ProductRoutes';
import CategoryRoutes from './views/category/CategoryRoutes';
import PipelineRoutes from './views/pipeline/PipelineRoutes';
import ProductTable from './form/ProductTable';
import TaskRoutes from './views/task/TaskRoutes';
import CustomerRoutes from './views/Customer/CustomerRoutes';
import TransRoutes from './views/finance/TransRoutes';
import OrderRoutes from './views/order/OrderRoutes';
import BankRoutes from './views/bank/BankRoutes';
import ProfileRoutes from './views/profile/ProfileRoutes';
import StaffRoutes from './views/staff/StaffRoutes';
import BannerRoutes from './views/banner/BannerRoutes';
import FeaturesRoutes from './views/features/FeaturesRoutes';
import AdRoutes from './views/ad/AdRoutes';
import BottomBannerRoutes from './views/bottom/BottomBannerRoutes';
import DiscountBannerRoutes from './views/discount/DiscountBannerRoutes';
const NotFound = Loadable(lazy(() => import('app/views/sessions/NotFound')));
const JwtLogin = Loadable(lazy(() => import('app/views/sessions/JwtLogin')));
const JwtRegister = Loadable(
	lazy(() => import('app/views/sessions/JwtRegister'))
);
const ForgotPassword = Loadable(
	lazy(() => import('app/views/sessions/ForgotPassword'))
);
// dashboard page
const Analytics = Loadable(lazy(() => import('app/views/dashboard/Analytics')));
const Inventory = Loadable(lazy(() => import('app/views/dashboard/Inventory')));

const routes = [
	{
		element: (
			<AuthGuard>
				<MatxLayout />
			</AuthGuard>
		),
		children: [
			{
				path: '/dashboard/default',
				element: <Analytics />,
				auth: authRoles.admin,
			},
			{
				path: '/dashboard/agenttran',
				element: <ProductTable />,
				auth: authRoles.admin,
			},
			{
				path: '/dashboard/inventory',
				element: <Inventory />,
				auth: authRoles.admin,
			},
			...CanbanBoardRoutes,
			...ProductRoutes,
			...CategoryRoutes,
			...TaskRoutes,
			...PipelineRoutes,
			...CustomerRoutes,
			...TransRoutes,
			...OrderRoutes,
			...BankRoutes,
			...ProfileRoutes,
			...StaffRoutes,
			...BannerRoutes,
			...FeaturesRoutes,
			...AdRoutes,
			...BottomBannerRoutes,
			...DiscountBannerRoutes,
		],
	},

	// session pages route
	{ path: '/session/404', element: <NotFound /> },
	{ path: '/session/signin', element: <JwtLogin /> },
	{ path: '/session/signup', element: <JwtRegister /> },
	{ path: '/session/forgot-password', element: <ForgotPassword /> },

	{ path: '/', element: <Navigate to='/session/signin' /> },
	{ path: '*', element: <NotFound /> },
];

export default routes;
