import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Profile = Loadable(lazy(() => import('./Profile')));
const ProfileSection = Loadable(lazy(() => import('./ProfileSection')));
const ChangePassword = Loadable(lazy(() => import('./ChangePassword')));
const Manage = Loadable(lazy(() => import('./Manage')));

const ProfileRoutes = [
	{
		path: '/dashboard/profile',
		element: <Profile />,
		auth: authRoles.admin,
	},
	{
		path: '/add/staff',
		element: <ProfileSection />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/changepassword',
		element: <ChangePassword />,
		auth: authRoles.admin,
	},
	{
		path: '/manage/markup',
		element: <Manage />,
		auth: authRoles.admin,
	},
];

export default ProfileRoutes;
