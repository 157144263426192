import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const CategoryList = Loadable(lazy(() => import('./CategoryList')));
const AddCategory = Loadable(lazy(() => import('./AddCategory')));
const EditCategory = Loadable(lazy(() => import('./EditCategoryForm')));

const CategoryRoutes = [
	{
		path: '/category/list',
		element: <CategoryList />,
		auth: authRoles.admin,
	},
	{
		path: '/category/list/edit',
		element: <EditCategory />,
		auth: authRoles.admin,
	},
	{
		path: '/category/add',
		element: <AddCategory />,
		auth: authRoles.admin,
	},
];

export default CategoryRoutes;
