import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from 'reducers/UiReducer';
import { updateStatusnotificationDispatch } from 'reducers/HomeReducer';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
	borderRadius: 5,
};

export default function NewOrderModal() {
	const dispatch = useDispatch();
	const handleClose = () => {
		let form = {
			status: '0',
		};
		dispatch(updateStatusnotificationDispatch(notify?.data[0]?.id, form));
	};
	const { notify, loginInfo } = useSelector((state) => state.home);
	const totalOrders = notify?.data?.length || 0; // Calculate total orders

	React.useEffect(() => {
		// Code for handling side effects if needed
	}, []);

	return (
		<div>
			<Modal
				open={notify ? (notify?.data[0]?.status === '0' ? false : true) : false}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box sx={style}>
					<IconButton
						aria-label='close'
						onClick={handleClose}
						sx={{
							position: 'absolute',
							top: 8,
							right: 8,
							color: (theme) => theme.palette.grey[500],
						}}>
						<CloseIcon />
					</IconButton>
					<Typography variant='h6' sx={{ mb: 2, textAlign: 'center' }}>
						🎉 Congratulations! New Order Received
					</Typography>
					<Typography variant='body1' sx={{ mt: 2, textAlign: 'center' }}>
						Total Orders: {notify?.data?.length}
					</Typography>
				</Box>
			</Modal>
		</div>
	);
}
