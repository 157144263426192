import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const BankList = Loadable(lazy(() => import('./BankList')));
const EditBank = Loadable(lazy(() => import('./EditBankForm')));

const BankRoutes = [
	{
		path: '/bank/list',
		element: <BankList />,
		auth: authRoles.admin,
	},
	{
		path: '/bank/list/edit',
		element: <EditBank />,
		auth: authRoles.admin,
	},
];

export default BankRoutes;
