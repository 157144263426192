import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const FeaturesList = Loadable(lazy(() => import('./FeaturesList')));
const AddFeatures = Loadable(lazy(() => import('./AddFeatures')));
const Edit = Loadable(lazy(() => import('./EditFeaturesForm')));

const FeaturesRoutes = [
	{
		path: '/feature/list',
		element: <FeaturesList />,
		auth: authRoles.admin,
	},
	{
		path: '/feature/list/edit',
		element: <Edit />,
		auth: authRoles.admin,
	},
	{
		path: '/feature/add',
		element: <AddFeatures />,
		auth: authRoles.admin,
	},
];

export default FeaturesRoutes;
