import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const FeaturesList = Loadable(lazy(() => import('./BottomBannerList')));
const AddFeatures = Loadable(lazy(() => import('./AddBottomBanner')));
const Edit = Loadable(lazy(() => import('./EditBottomForm')));

const BottomBannerRoutes = [
	{
		path: '/bottombanner/list',
		element: <FeaturesList />,
		auth: authRoles.admin,
	},
	{
		path: '/bottombanner/list/edit',
		element: <Edit />,
		auth: authRoles.admin,
	},
	{
		path: '/bottombanner/add',
		element: <AddFeatures />,
		auth: authRoles.admin,
	},
];

export default BottomBannerRoutes;
