import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Analytics = Loadable(lazy(() => import('./ProductSection')));
const ProductList = Loadable(lazy(() => import('./ProductList')));
const ProductEditList = Loadable(
	lazy(() => import('../../form/EditProductForm'))
);

const ProductRoutes = [
	{
		path: '/product/add',
		element: <Analytics />,
		auth: authRoles.admin,
	},
	{
		path: '/product/list',
		element: <ProductList />,
		auth: authRoles.admin,
	},
	{
		path: '/product/edit',
		element: <ProductEditList />,
		auth: authRoles.admin,
	},
];

export default ProductRoutes;
