import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const TaskList = Loadable(lazy(() => import('./TaskList')));

const TaskRoutes = [
	{
		path: '/task/list',
		element: <TaskList />,
		auth: authRoles.admin,
	},
];

export default TaskRoutes;
