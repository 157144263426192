import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { delteProductDispatch } from 'reducers/HomeReducer';
import {
	Box,
	Button,
	Modal,
	TextField,
	Typography,
	Divider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function ProductTable() {
	const { product, loginInfo } = useSelector((state) => state.home);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [searchText, setSearchText] = React.useState('');
	const [filteredRows, setFilteredRows] = React.useState(product?.result || []);

	// Modal state for viewing images
	const [openModal, setOpenModal] = React.useState(false);
	const [selectedImage, setSelectedImage] = React.useState(null);

	const handleImageClick = (image) => {
		setSelectedImage(image);
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
		setSelectedImage(null);
	};

	const columns = [
		{
			field: 'id',
			headerName: 'Id',
			width: 100,
		},
		{
			field: 'name',
			headerName: 'Product Name',
			width: 200,
			editable: true,
		},
		{
			field: 'desc',
			headerName: 'Description',
			width: 300,
		},
		{
			field: 'price_per',
			headerName: 'Price Per',
			width: 150,
		},
		{
			field: 'min_order',
			headerName: 'Min Order',
			width: 150,
		},
		{
			field: 'max_order',
			headerName: 'Max Order',
			width: 150,
		},
		{
			field: 'total_quantity',
			headerName: 'Stock Available',
			width: 200,
		},
		{
			field: 'imgpath',
			headerName: 'Thumbnail',
			width: 150,
			renderCell: (cellValues) => (
				<Box style={{ display: 'flex', justifyContent: 'center' }}>
					<img
						src={cellValues.row.imgpath}
						style={{
							height: 50,
							width: 50,
							cursor: 'pointer',
							borderRadius: '5px',
						}}
						alt='Thumbnail'
						onClick={() => handleImageClick(cellValues.row.imgpath)}
					/>
				</Box>
			),
		},
		{
			field: 'multipleimage',
			headerName: 'Images',
			width: 300,
			renderCell: (cellValues) => (
				<Box style={{ display: 'flex', gap: 8 }}>
					{JSON.parse(cellValues.row.multipleimage).map((image, index) => (
						<img
							key={index}
							src={image}
							style={{
								height: 50,
								width: 50,
								cursor: 'pointer',
								borderRadius: '5px',
							}}
							alt={`Image ${index + 1}`}
							onClick={() => handleImageClick(image)}
						/>
					))}
				</Box>
			),
		},
		{
			field: 'action',
			headerName: 'Action',
			width: 300,
			renderCell: (cellValues) => (
				<Box style={{ display: 'flex', justifyContent: 'space-between' }}>
					<a
						onClick={() => {
							navigate('/product/edit', {
								state: cellValues.row,
							});
						}}
						href='javascript:void(0);'
						className='text-info fs-14 lh-1'
						style={{ marginRight: 10 }}>
						<i className='ri-edit-line'></i>
					</a>
					<a
						onClick={() => {
							Swal.fire({
								title: 'Are you sure?',
								text: "You won't be able to revert this!",
								icon: 'warning',
								showCancelButton: true,
								confirmButtonColor: '#3085d6',
								cancelButtonColor: '#d33',
								confirmButtonText: 'Yes, delete it!',
							}).then((result) => {
								if (result.isConfirmed) {
									dispatch(delteProductDispatch(cellValues.id, Swal));
								}
							});
						}}
						href='javascript:void(0);'
						className='text-danger fs-14 lh-1'>
						<i className='ri-delete-bin-5-line'></i>
					</a>
				</Box>
			),
		},
	];
	React.useEffect(() => {
		if (product?.result) {
			setFilteredRows(
				product.result.filter(
					(row) =>
						row.name.toLowerCase().includes(searchText.toLowerCase()) ||
						row.desc.toLowerCase().includes(searchText.toLowerCase())
				)
			);
		}
	}, [searchText, product?.result]);

	const handleSearchChange = (event) => {
		setSearchText(event.target.value);
	};
	return (
		<Box sx={{ height: 950, width: '100%' }}>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					marginBottom: 2,
				}}>
				<h3 style={{ fontSize: 20 }}>Product List</h3>
				<TextField
					label='Search'
					variant='outlined'
					size='small'
					style={{ width: '50%' }}
					value={searchText}
					onChange={handleSearchChange}
					fullWidth
				/>
			</Box>
			<Divider style={{ marginTop: 20, marginBottom: 20 }} />
			{filteredRows?.length > 0 ? (
				<DataGrid
					sx={{
						'&.MuiDataGrid-root .MuiDataGrid-cell:focus': { outline: 'none' },
						'&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
							outline: 'none',
						},
						'& .MuiDataGrid-virtualScroller': {
							'&::-webkit-scrollbar': {
								height: '8px',
								backgroundColor: '#f5f5f5',
							},
							'&::-webkit-scrollbar-thumb': {
								backgroundColor: '#888',
								borderRadius: '4px',
							},
							'&::-webkit-scrollbar-thumb:hover': { backgroundColor: '#555' },
						},
					}}
					rows={filteredRows}
					columns={columns}
					initialState={{
						pagination: {
							paginationModel: { pageSize: 15 },
						},
					}}
					pageSizeOptions={[15]}
					disableRowSelectionOnClick
				/>
			) : (
				<p>No Products Available</p>
			)}

			{/* Modal for Viewing Images */}
			<Modal open={openModal} onClose={handleCloseModal}>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						bgcolor: 'background.paper',
						boxShadow: 24,
						p: 4,
						borderRadius: '10px',
					}}>
					{selectedImage && (
						<img
							src={selectedImage}
							alt='Selected'
							style={{
								maxHeight: '80vh',
								maxWidth: '80vw',
								borderRadius: '10px',
							}}
						/>
					)}
				</Box>
			</Modal>
		</Box>
	);
}
