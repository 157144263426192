import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const BannerList = Loadable(lazy(() => import('./BannerList')));
const AddBanner = Loadable(lazy(() => import('./AddBanner')));

const BannerRoutes = [
	{
		path: '/banner/list',
		element: <BannerList />,
		auth: authRoles.admin,
	},
	{
		path: '/banner/add',
		element: <AddBanner />,
		auth: authRoles.admin,
	},
];

export default BannerRoutes;
