import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Analytics = Loadable(lazy(() => import('./CustomerSection')));
const ProductList = Loadable(lazy(() => import('./CustomerList')));
const EditPipeline = Loadable(lazy(() => import('./CustomerEdit')));
const Trans = Loadable(lazy(() => import('./CustomerTransList')));

const CustomerRoutes = [
	{
		path: '/customer/add',
		element: <Analytics />,
		auth: authRoles.admin,
	},
	{
		path: '/customer/list',
		element: <ProductList />,
		auth: authRoles.admin,
	},
	{
		path: '/customer/list/trans',
		element: <Trans />,
		auth: authRoles.admin,
	},
	{
		path: '/customer/list/edit',
		element: <EditPipeline />,
		auth: authRoles.admin,
	},
];

export default CustomerRoutes;
