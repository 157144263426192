import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const FeaturesList = Loadable(lazy(() => import('./DiscountBannerList')));
const Edit = Loadable(lazy(() => import('./EditDiscountForm')));

const DiscountBannerRoutes = [
	{
		path: '/discountbanner/list',
		element: <FeaturesList />,
		auth: authRoles.admin,
	},
	{
		path: '/discountbanner/list/edit',
		element: <Edit />,
		auth: authRoles.admin,
	},
];

export default DiscountBannerRoutes;
