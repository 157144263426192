import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Analytics = Loadable(lazy(() => import('./PipelineSection')));
const ProductList = Loadable(lazy(() => import('./PipelineList')));
const EditPipeline = Loadable(lazy(() => import('./PipelineEdit')));

const PipelineRoutes = [
	{
		path: '/pipeline/add',
		element: <Analytics />,
		auth: authRoles.admin,
	},
	{
		path: '/pipeline/list',
		element: <ProductList />,
		auth: authRoles.admin,
	},
	{
		path: '/pipeline/edit',
		element: <EditPipeline />,
		auth: authRoles.admin,
	},
];

export default PipelineRoutes;
