import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
const CanbanBoardList = Loadable(lazy(() => import('./CanbanBoardList')));
const AddLead = Loadable(lazy(() => import('./AddLead')));

const CanbanBoardRoutes = [
	{
		path: '/board/addlead',
		element: <AddLead />,
		auth: authRoles.admin,
	},
	{
		path: '/board/list',
		element: <CanbanBoardList />,
		auth: authRoles.admin,
	},
];

export default CanbanBoardRoutes;
