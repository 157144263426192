import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const StaffList = Loadable(lazy(() => import('./StaffList')));
const ProfileSection = Loadable(lazy(() => import('./StaffSection')));

const StaffRoutes = [
	{
		path: '/staff/list',
		element: <StaffList />,
		auth: authRoles.admin,
	},
	{
		path: '/add/staff',
		element: <ProfileSection />,
		auth: authRoles.admin,
	},
];

export default StaffRoutes;
